import $ from "jquery";
import "imports-loader?jQuery=jquery,define=>false,module.exports=>false!jquery-validation";

class DonationForm {
  constructor() {
    if ($(".donate-form__wrapper").length) {
      this.updatePreviewText();
      this.clickEvents();
      this.submitOrder();
      this.submitPan();
      this.runValidations();
      $('.js-invoice-mail').hide(); //default this to hidden
    }
  }

  /**
   * Add text to preview PDF view from input fields
   */
  updatePreviewText() {
    if ($(".donate-form__pdf-input").length) {
      /** Update headline */
      $("#pdf-preview-headline").keyup(function () {
        $(".js-donate-pdf-headline").text($(this).val());
      });

      /** Update first name */
      $("#pdf-preview-first-name").keyup(function () {
        $(".js-donate-pdf-firstname").text($(this).val());
      });

      /** Update last name */
      $("#pdf-preview-last-name").keyup(function () {
        $(".js-donate-pdf-lastname").text($(this).val());
      });

      /** Update message */
      $("#pdf-preview-message").keyup(function () {
        let messageInput = $("#pdf-preview-message").val();
        messageInput = messageInput.replace(/(?:\r\n|\r|\n)/g, "<br />");
        $(".js-donate-pdf-preview-message").html(messageInput);
      });

      /** Update message */
      $("#pdf-preview-honored-by").keyup(function () {
        let honoredInput = $("#pdf-preview-honored-by").val();
        honoredInput = honoredInput.replace(/(?:\r\n|\r|\n)/g, "<br />");
        $(".js-donate-pdf-honored-by").html(honoredInput);
      });



      /** Limit message textarea to 10 rows if writing */
      let lines = 10;
      $("#pdf-preview-message").keydown(function (e) {
        let newLines = $(this).val().split("\n").length;
        if (e.keyCode == 13 && newLines >= lines) {
          return false;
        }
      });

      /** Display remaining characters input field */
      $("#pdf-preview-message").on("input", function () {
        let currentLength = $("#pdf-preview-message").val().length;
        $(".js-donate-count-message span").text(currentLength);
      });

      /** Create clone of preview PDF HTML */
      $(".js-donate-pdf-preview").on("click", function (e) {
        e.preventDefault();
        $('.site-overlay').fadeIn().addClass('active');
        $('.donate-form__pdf-preview-modal').addClass('active');
        $('.js-donate-pdf-preview--large').html('');
        let clonePdfPreview = $('.donate-form__pdf-preview-wrapper').clone();
        $('.js-donate-pdf-preview--large').addClass("active").html(clonePdfPreview);
      });

      /** Remove site-overlay on modal__close click */
      $('.donate-form__pdf-preview-modal__close').click(function (e) {
        e.preventDefault();
        $('.site-overlay').fadeOut().removeClass('active');
        $('.donate-form__pdf-preview-modal').removeClass('active');
        $('.modal').attr('aria-hidden', 'true');
      });
    }
  }

  /**
   * Click events
   */
  clickEvents() {
    /** Disable form form entering when pressing Enter-key if not submit */
    $(document).on("keydown", ":input:not(textarea):not(:submit)", function (event) {
      return event.key != "Enter";
    });

    /** Uncheck amount switch button if float amount is filled in */
    $("input[name=float_amount]").keyup(function () {
      if ($("input[name=float_amount]").val()) {
        $("input[name=amount]").prop("checked", false);
      }
    });

    /** Clear float amount if amount is checked */
    $("input[name=amount]").click(function () {
      $("input[name=float_amount]").val("");
      $(".amount_float").removeClass("has-value");
    });

    /** Hide print if digital certificate is chosen */
    $('input[name="certificate_type"]').on('click', function () {
      let cetificate_type = $('input[name="certificate_type"]:checked').val();
      if (cetificate_type === 'printed') {
        $('.js-certificate_type-print').show();
        $('.js-certificate_type-digital-email').hide();
      }
      if (cetificate_type === 'digital') {
        $('.js-certificate_type-print').hide();
        $('.js-certificate_type-digital-email').show();
      }
    });

    /** Show invoice email if invoice is selected */
    $('input[name="payment-option"]').on('click', function () {
      let payment_method = $('input[name="payment-option"]:checked').val();
      if (payment_method !== 'invoice') {
        $('input[name="invoice_email"').prop("required", false);
        $('.js-invoice-mail').hide();
      }
      if (payment_method === 'invoice') {
        $('input[name="invoice_email"').prop("required", true);
        $('.js-invoice-mail').show();
      }
    });

    /** Choose payment interval option to enable/disable recurring payment */
    $('input[name=payment_type_option]').on('click', function () {
      let payment_interval_method = $('input[name="payment_type_option"]:checked').val();
      if (payment_interval_method == 'autogiro') {
        $('input[name=payment_interval]').val('autogiro');
        $('input[name=donation_type]').val('autogiro');
        $('input[name="payment-option"]').prop('checked', false);
        $('.js-payment-card input').prop('checked', true);
        $('.js-payment-card').addClass('active');
        $('.js-payment-swish').hide();
      }
      if (payment_interval_method == 'recurring_option') {
        $('input[name=payment_interval]').val('recurring');
        $('input[name=donation_type]').val('monthly_donor');
        $('input[name="payment-option"]').prop('checked', false);
        $('.js-payment-card input').prop('checked', true);
        $('.js-payment-card').addClass('active');
        $('.js-payment-swish').hide();
      }
      if (payment_interval_method == 'onetime_option') {
        $('input[name=payment_interval]').val('onetime');
        $('input[name=donation_type]').val('donation');
        $('input[name="payment-option"]').prop('checked', false);
        $('.js-payment-card input').prop('checked', true);
        $('.js-payment-card').addClass('active');
        $('.js-payment-swish').show();
      }
    });
    $('.js-payment-swish').on('click', function () {
      $('.js-payment-card').removeClass('active');
    });

  }

  /**
   * Loads PayEx payment menu on different actions
   *
   * @param {func}
   *
   * @return {void}
   */
  submitOrder() {
    /**
     * Loads PayEx payment menu on different actions
     */
    $(".js-submit-order").on("click", function (e) {
      e.preventDefault();

      const form = $(".donate-form");

      const data = {
        firstname: form.find("input[name=firstname]").val(),
        lastname: form.find("input[name=lastname]").val(),
        person_ssn: form.find("input[name=person_ssn]").val(),
        email: form.find("input[name=email]").val(),
        phone: form.find("input[name=phone]").val(),
        address: form.find("input[name=address]").val(),
        postalcode: form.find("input[name=postalcode]").val(),
        city: form.find("input[name=city]").val(),
        country: form.find("input[name=country]").val(),
        amount: form.find("input[name=amount]:checked").val(),
        float_amount: form.find("input[name=float_amount]").val(),
        payment_interval: form.find("input[name=payment_interval]").val(),
        payment_method: form.find("input[name=payment-option]:checked").val(),
        donation_type: form.find("input[name=donation_type]").val(),
        donor_type: form.find("input[name=donor_type]").val(),
        donation_page_slug: form.find("input[name=donation_page_slug]").val(),
        // redirect pages
        thank_you_page: form.find("input[name=thank_you_page]").val(),
        error_page: form.find("input[name=error_page]").val(),
        // bank info
        bank_name: form.find('input[name=bank_name]').val(),
        bank_clearing: form.find('input[name=bank_clearing]').val(),
        bank_account: form.find('input[name=bank_account]').val(),

        //Company fields
        company: form.find("input[name=company]").val(),
        org_number: form.find("input[name=org_number]").val(),
        invoice_email: form.find("input[name=invoice_email]").val(),
        //Honorary/gift specific data
        certificate_type: form.find("input[name=certificate_type]:checked").val(),
        contact_email_check: form.find("input[name=contact_email_check]:checked").val(),
        // notification_to: form.find("input[name=notification_to]").val(),
        honoree_headline: form.find("input[name=honoree_headline]").val(),
        honored_by: form.find("input[name=honored_by]").val(),
        honoree_firstname: form.find("input[name=honoree_firstname]").val(),
        honoree_lastname: form.find("input[name=honoree_lastname]").val(),
        honoree_note: form.find("textarea[name=honoree_note]").val(),
        honoree_note_recipient: form.find("input[name=honoree_note_recipient]").val(),
        honoree_note_recipient_email: form.find("input[name=honoree_note_recipient_email]").val(),
        honoree_note_recipient_contact_email: form.find("input[name=honoree_note_recipient_contact_email]").val(),
        honoree_note_street_adress: form.find("input[name=honoree_note_street_adress]").val(),
        honoree_note_co_adress: form.find("input[name=honoree_note_co_adress]").val(),
        honoree_note_street_zip: form.find("input[name=honoree_note_street_zip]").val(),
        honoree_note_street_city: form.find("input[name=honoree_note_street_city]").val(),
      };

      /** @type {string} */
      let amount = data.amount;

      if (data.float_amount) {
        /** @type {string} */
        amount = data.float_amount;
        $(".amount--predefined-wrapper").addClass("disable");
        $(".amount_float").removeClass("disable");
      } else {
        amount = data.amount;
        $(".amount_float").addClass("disable");
        $(".amount--predefined-wrapper").removeClass("disable");
      }

      /** Save data to sessionStorage */
      sessionStorage.setItem("DonationForm", JSON.stringify(data));

      /** @type {string} Nonce */
      const nonce = $(form).find("input[name=nonce]").val();

      /** @type {string} Nonce */
      const button_text = $(this).text();

      /**
       * Load data from sessionStorage
       *
       * @type {object}
       */
      // const order = JSON.parse(sessionStorage.getItem("DonationForm"));

      /** @type {object} */
      const order_data = {
        action: data.donation_type === 'autogiro' ? 'autogiro_init' : 'payex_paymentmenu_init',
        order: {
          donation_type: data.donation_type,
          donor_type: data.donor_type,
          interval: data.payment_interval,
          total: data.float_amount ? data.float_amount : data.amount,
          firstname: data.firstname,
          lastname: data.lastname,
          person_ssn: data.person_ssn,
          phone: data.phone,
          email: data.email,
          address: data.address,
          postalcode: data.postalcode,
          city: data.city,
          country: data.country,
          payment_method: data.payment_method,
          donation_page_slug: data.donation_page_slug,
          // bank info
          bank_name: data.bank_name,
          bank_clearing: data.bank_clearing,
          bank_account: data.bank_account,
          // redirect pages
          thank_you_page: data.thank_you_page,
          error_page: data.error_page,
          // Company fields
          company: data.company,
          org_number: data.org_number,
          invoice_email: data.invoice_email,
          //Honorary/gift specific data
          certificate_type: data.certificate_type,
          notification_to: 'Donor',
          honoree_headline: data.honoree_headline,
          honored_by: data.honored_by,
          honoree_firstname: data.honoree_firstname,
          honoree_lastname: data.honoree_lastname,
          honoree_note: data.honoree_note,
          honoree_note_recipient: data.honoree_note_recipient,
          contact_email_check: data.contact_email_check,
          honoree_note_recipient_email: data.honoree_note_recipient_email,
          honoree_note_street_adress: data.honoree_note_street_adress,
          honoree_note_co_adress: data.honoree_note_co_adress,
          honoree_note_street_zip: data.honoree_note_street_zip,
          honoree_note_street_city: data.honoree_note_street_city
        },
        security: nonce,
      };

      $.ajax({
        method: "post",
        url: adminAjaxURL,
        data: order_data,
        beforeSend: () => {
          // Disable submit button
          $(this).text("Hanterar order");
          $(this).attr("disabled", true);

          // Add loading
          $("body").addClass("loading");
        },
        success: (response) => {
          // Remove loading
          $("body").removeClass("loading");

          if (response.status === 200 && response.href) {
            // Redirect to hosted payment page
            try {
              //alert('trying location replace')
              window.location.replace(response.href);
            }
            catch (e) {
              //alert('trying location assign')
              window.location = response.href;
            }
            //window.location.replace(response.href);
          }

          // TODO: Show error message in a nice way
          if (response.status != 200) {
            if (response.message) {
              alert(response.message);
            } else {
              alert(
                "Hoppsan, något gick fel. Försök igen eller kontakta Ericastiftelsen givarservice@ericastiftelsen.se"
              );
            }
          }
          // Enable submit button
          $(this).text(button_text);
          $(this).attr("disabled", false);
        },
        error: (error) => {
          console.log(error);
          alert("Hoppsan, nåt gick fel");

          // Remove loading
          $("body").removeClass("loading");

          // Enable submit button
          $(this).text(button_text);
          $(this).attr("disabled", false);
        },
      });
    });
  }

  /**
   * Submit PAN form within form to collect user data and prefill
   * fields in main form
   *
   * @param {func}
   *
   * @return {void}
   */
  submitPan() {
    /** @type {object} instance of DonationForm */

    $("#pan-button").on("click", function (e) {
      e.preventDefault();
    });

    const form = $(".donate-form");
    const that = this;

    $(".js-submit-pan").on("click", function (e) {
      e.preventDefault();

      /** @type {string} Personal number from input */
      const pan = $(form).find("input[name=pan]").val();

      /** @type {string} Nonce */
      const nonce = $(form).find("input[name=nonce]").val();

      $.ajax({
        method: "post",
        url: adminAjaxURL,
        data: {
          action: "get_person_data_spar",
          pan: pan,
          security: nonce,
        },
        beforeSend: () => {
          // Disable submit button
          $(this).text("Hämtar info");
          $(this).attr("disabled", true);
        },
        success: (response) => {
          // Enable submit button
          $(this).text("Hämta mina uppgifter");
          $(this).attr("disabled", false);

          // Handle error
          if (!response || response == 0) {
            alert(
              "Hoppsan, vi kunde inte hämta din information, kontrollera ditt personnummer eller fyll i informationen manuellt."
            );
            console.log(response);
            return;
          }
          // Populate user address data in form
          if (response.Adresser.Folkbokforingsadress) {
            /** @type {object} Get href from <a> */
            let a = {};

            // If object is array, get first item, otherwise just get object
            if (Array.isArray(response.Adresser.Folkbokforingsadress)) {
              /** @type {object} Get href from <a> */
              a = response.Adresser.Folkbokforingsadress[0];
            } else {
              /** @type {object} Get href from <a> */
              a = response.Adresser.Folkbokforingsadress;
            }

            // Adress
            $(form).find("input[name=address]").val(a.Utdelningsadress2).removeClass("error");
            $(form).find("input[name=address]").parent().addClass("has-value");
            $(form).find("#address-error").removeClass("error").hide();

            // Postal code
            $(form).find("input[name=postalcode]").val(a.PostNr).removeClass("error");
            $(form).find("input[name=postalcode]").parent().addClass("has-value");
            $(form).find("#postalcode-error").removeClass("error").hide();

            // City
            $(form).find("input[name=city]").val(a.Postort).removeClass("error");
            $(form).find("input[name=city]").parent().addClass("has-value");
            $(form).find("#city-error").removeClass("error").hide();
          }

          // Populate user data in form
          if (response.Persondetaljer) {
            // First name
            $(form).find("input[name=firstname]").val(response.Persondetaljer.Fornamn).removeClass("error");
            $(form).find("input[name=firstname]").parent().addClass("has-value");
            $(form).find("#firstname-error").removeClass("error").hide();
            // Last name
            $(form).find("input[name=lastname]").val(response.Persondetaljer.Efternamn).removeClass("error");
            $(form).find("input[name=lastname]").parent().addClass("has-value");
            $(form).find("#lastname-error").removeClass("error").hide();
          }
        },
        error: (error) => {
          // Enable submit button
          $(this).text("Hämta mina uppgifter");
          $(this).attr("disabled", false);

          alert(
            "Hoppsan, vi kunde inte hämta din information, kontrollera ditt personnummer eller fyll i informationen manuellt."
          );
        },
      });
    });
  }

  /**
   * Run validations
   *
   * @return {void}
   */
  runValidations() {
    const me = this;

    $("#donation-form input").on("keyup", function () {
      me.validateForm();
    });

    $('#donation-form input[type="checkbox"], #donation-form input[type="radio"]').change(function () {
      me.validateForm();
    });
  }

  /**
   * Run validation on input fields
   */
  validateForm() {
    $.validator.addMethod(
      "regex",
      function (value, element, regexp) {
        if (regexp.constructor != RegExp) regexp = new RegExp(regexp);
        else if (regexp.global) regexp.lastIndex = 0;
        return this.optional(element) || regexp.test(value);
      },
      'Se över dina fält.'
    );
    $.validator.addMethod(
      'personNrCheck',
      function (value,) {
        value = value.replace(/\D/g, "");
        // Extract the birthdate (YYYYMMDD)
        const birthdate = value.slice(0, 8);
        // Check if the birthdate is a valid date
        if (!/^(19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[1-2]\d|3[0-1])$/.test(birthdate)) {
          return false;
        }
        value = value
          .replace(/\D/g, "")     // strip out all but digits
          .split("")              // convert string to array
          .reverse()              // reverse order for Luhn
          .slice(0, 10);          // keep only 10 digits (i.e. 1977 becomes 77)
        // Check if the input is exactly 12 characters long (YYYYMMDDXXXX)
        if (value.length !== 10) {
          return false;
        }
        var sum = value
          // convert to number
          .map(function (n) {
            return Number(n);
          })
          // perform arithmetic and return sum
          .reduce(function (previous, current, index) {
            // multiply every other number with two
            if (index % 2) current *= 2;
            // if larger than 10 get sum of individual digits (also n-9)
            if (current > 9) current -= 9;
            // sum it up
            return previous + current;
          });

        // sum must be divisible by 10
        return 0 === sum % 10;
      },
      'Se över dina fält.'
    );
    $("#donation-form").validate({
      rules: {
        firstname: {
          required: true,
          minlength: 2,
          maxlength: 50,
        },
        lastname: {
          required: true,
          minlength: 1,
          maxlength: 50,
        },
        email: {
          required: true,
          email: true,
        },
        phone: {
          required: true,
          regex: /^\+46 *7[0236] *\d{4} *\d{3}$/,
        },
        address: {
          required: true,
        },
        city: {
          required: true,
        },
        postalcode: {
          required: true,
          minlength: 5,
          maxlength: 5,
        },
        country: {
          required: true,
        },
        policy: {
          required: true,
        },
        // Give gift
        give_firstname: {
          required: true,
          minlength: 2,
          maxlength: 50,
        },
        give_lastname: {
          required: true,
          minlength: 2,
          maxlength: 50,
        },
        give_message: {
          required: true,
          minlength: 2,
          maxlength: 300,
        },
        contact_email: {
          required: true,
        },
        invoice_email: {
          required: true,
        },
        company: {
          required: true,
          minlength: 2,
          maxlength: 50,
        },
        org_number: {
          required: true,
          regex: /([1-9][0-9]([1][3-9]|[2-9][0-9])\d{2}[-]?\d{4})/,
        },
        bank_clearing: {
          required: true,
          minlength: 4,
          maxlength: 4,
        },
        bank_account: {
          required: true,
        },
        bank_name: {
          required: true,
        },
        person_ssn: {
          required: true,
          personNrCheck: true,
          minlength: 12,
          maxlength: 12,
        }
      },
      messages: {
        required: "Ange ditt telefonnummer",
        firstname: {
          required: "Vänligen fyll i ditt förnamn",
          minlength: "Minst 2 bokstäver",
          maxlength: "Oj nu blev det lite för långt",
        },
        lastname: {
          required: "Vänligen fyll i ditt efternamn",
          minlength: "Minst 2 bokstäver",
          maxlength: "Oj nu blev det lite för långt",
        },
        email: "Vänligen ange din e-post",
        phone: {
          required: "Ange ditt telefonnummer med +46",
          regex: "Format med +46, ex: +46701234567",
        },
        address: "Vänligen ange din adress",
        city: "Vänligen fyll i mottagarort",
        postalcode: {
          required: "Vänligen fyll i ditt postnummer",
          minlength: "Ditt postnummer ska vara 5 siffror",
          maxlength: "Ditt postnummer består av 5 siffror",
        },
        country: "Vänligen ange ditt land",
        policy: {
          required: "",
        },
        //Give gift
        give_firstname: {
          required: "Vänligen fyll i ditt förnamn",
          minlength: "Minst 2 bokstäver",
          maxlength: "Oj nu blev det lite för långt",
        },
        give_lastname: {
          required: "Vänligen fyll i ditt efternamn",
          minlength: "Minst 2 bokstäver",
          maxlength: "Oj nu blev det lite för långt",
        },
        give_message: {
          required: "Vänligen fyll i meddelande",
          minlength: "Minst 2 bokstäver",
          maxlength: "Oj nu blev det lite för långt",
        },
        bank_clearing: {
          required: 'Vänligen fyll i clearingnummer',
          minlength: '4 siffor krävs',
          maxlength: 'Max 4 siffror',
        },
        bank_account: {
          required: 'Vänligen fyll i kontonummer',
        },
        bank_name: {
          required: 'Vänligen fyll i bankens namn',
        },
        person_ssn: {
          minlength: '12 siffor krävs',
          maxlength: 'Max 12 siffror',
          personNrCheck: 'Ogiltigt personnummer',
        },
        honoree_firstname: "Vänligen fyll i förnamn",
        honoree_lastname: "Vänligen fyll i efternamn",
        honored_by: "Vänligen fyll i avsändare",
        honoree_note: "Vänligen fyll i meddelande",
        honoree_note_recipient_email: "Vänligen ange e-post till mottagare",
        honoree_note_recipient: 'Vänligen fyll i namn',
        honoree_note_street_adress: 'Vänligen fyll i address',
        honoree_note_street_zip: 'Vänligen fyll i postnummer',
        honoree_note_street_city: 'Vänligen fyll i postort',
        invoice_email: "Vänligen ange e-post för fakturering",
        company: {
          required: "Ange företagsnamn",
          minlength: "Minst 2 bokstäver",
          maxlength: "Oj nu blev det lite för långt",
        },
        org_number: {
          required: "Ange organisationsnummer",
          regex: "Vänligen ange ett giltigt org-nummer",
        },
        float_amount: {
          min: $.validator.format("Minsta tillåtna värde är {0}."),
        },
      },
      submitHandler: function (form) {
        alert("valid form submitted"); // for demo
      },
    });


    if ($("#donation-form").valid()) {
      $("#donation-form").removeClass("disabled");
      $(".js-submit-order").attr("disabled", false);
    } else {
      $("#donation-form").addClass("disabled");
      $(".js-submit-order").attr("disabled", true);
    }
  }
}

new DonationForm();
