import $ from "jquery";
import Cookies from "js-cookie";
import "imports-loader?jQuery=jquery,define=>false,module.exports=>false!jquery-validation";

class User {
  constructor() {
    this.submitForm();
    this.validateForm();
  }

  /**
   * User submit email form
   *
   * @return {void}
   */
  submitForm() {
    const me = this;

    $(".js-ajax-user").on("submit", function (e) {
      e.preventDefault();
      var formData = [];
      $.each($(this).serializeArray(), function (i, field) {
        formData[field.name] = field.value;
      });

      me.handleUser(formData);
    });
  }

  /**
   * Handle user interaction
   *
   * @param {array}
   *
   * @return {void}
   */
  handleUser(formData) {
    console.log(formData);

    $.ajax({
      url: adminAjaxURL,
      type: "POST",
      dataType: "json",
      data: {
        action: "user_init_ajax",
        security: formData["user_nonce"],
        user_email: formData["user_email_1"],
        user_confirm_email: formData["user_email_2"],
        first_name: formData["first_name"],
        last_name: formData["last_name"],
        personal_number: formData["personal_number"],
        city: formData["city"],
        address: formData["address"],
        postal_code: formData["postal_code"],
      },
      beforeSend: function () {
        $("#user-error-message").hide();
      },
      success: function (data) {
        if (data.redirect && data.redirect_url) {
          window.location.replace(data.redirect_url);
          return;
        }
        if (data == "email_exists") {
          $("#user-error-message").text("Email exists.").show();
        }
      },
      error: function (error) {
        console.log(error);
      },
    });
  }

  validateForm() {
    $(".js-ajax-user").validate({
      rules: {
        user_email_1: {
          required: true,
          email: true,
        },
        user_email_2: {
          required: true,
          email: true,
          equalTo: "#user_email_1",
        },
      },
      messages: {
        user_email_1: {
          required: "Var god skriv in din e-postadress.",
        },
        user_email_2: {
          required: "Var god skriv in din e-postadress.",
          equalTo: "Ange samma e-postadress.",
        },
      },
    });

    $(".js-ajax-user input").on("keyup", function () {
      if ($(".js-ajax-user").valid()) {
        $(".js-ajax-user button").attr("disabled", false);
      } else {
        $(".js-ajax-user button").attr("disabled", true);
      }
    });
  }
}

new User();
