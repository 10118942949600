import $ from "jquery";

/**
 * Load more news Ajax on news page
 */
$(document).on('click', '.js-news-load-more', function(event) {
  event.preventDefault();
  var button = $(this);
  var container = $('.news-list');
  var page = parseInt(button.attr('data-paged'));
  var total = parseInt(button.attr('data-total'));
  var nextPage = page + 1;
  // console.log(page);
  button.text('Laddar...');
  
  $.ajax({
    type: "POST",
    /* admin-ajax url from variable in header.twig */
    url: adminAjaxURL,
    data: {
      action: "news_load_more",
      page: page,
      total: total
    },

    success: function(response) {
      // console.log(response);

      /* Put response in this element */
      $(".news-list").append(response);

      /* Change button text on success */
      button.text('Ladda fler');
      button.attr('data-paged', nextPage);

      /* Find what page we are on in pagination to check if we are close to last page */ 
      var lastCount = container.find('.news-list-item').length;

      /* If we are on last page, change text on button */ 
      if(total <= lastCount) {
        button.text('Inga fler inlägg');
        button.prop('disabled', true);
        button.addClass('disabled');
      }

      return false;
    }

  });
});