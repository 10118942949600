import $ from "jquery";

class Form {
  constructor() {
    this.handleInputChange();
    this.ifInputHasValue();
  }

  /**
   * Do something on input interractions
   */
  handleInputChange() {
    const self = this;

    $("input").on("keyup", function(e) {
      let me = $(this);
      let parent = $(me).parent();
      if ($(parent).hasClass("form-input-wrap")) {
        self.ifInputHasValue();
      }
    });
    $("input").on("focus", function(e) {
      let me = $(this);
      let parent = $(me).parent();
      if ($(parent).hasClass("form-input-wrap")) {
        $(parent).addClass("has-focus");
      }
    });
    $("input").on("blur", function(e) {
      let me = $(this);
      let parent = $(me).parent();
      if ($(parent).hasClass("form-input-wrap")) {
        $(parent).removeClass("has-focus");
      }
    });
  }

  /**
   * Go through all instances of class .input-wrap
   * and check if inputs has values, if they do,
   * add class "has-value" so we can position label.
   */
  ifInputHasValue() {
    if ($(".form-input-wrap").length) {
      $(".form-input-wrap").each(function(i, obj) {
        var me = $(this);
        var input = $(me).find("input");
        var value = $(input).val();
        if (value) {
          $(me).addClass("has-value");
        } else {
          $(me).removeClass("has-value");
        }
      });
    }
  }
}

new Form();
