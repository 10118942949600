import $ from "jquery";

/* Open the first section on load */
$(".js-tab-title-first").addClass('current');
  var openOnLoad = $(".js-tab-title-first").next('.collapsing-section');
$(openOnLoad).show();

$(".js-tab-title, .js-tab-title-first").on('focus', function () {  
 if (!$(this).data("mouseDown"))
    $(this).click();
  });

$(".js-tab-title").on('mousedown', function () {
  $(this).data("mouseDown", true);
});

$(".js-tab-title").on('mouseup', function () {
  $(this).removeData("mouseDown");
});

$(".js-tab-title").on('click', function (e) {
 let tabid = $(this).attr('data-tab');
 console.log(tabid);

  if ($(this).hasClass('js-tab-title-first-' +tabid)) {
    $('.js-tab-title-first-' +tabid).removeClass('current');
    $('.js-tab-title-first-' +tabid).attr("aria-selected", "false");
    $('.js-tab-title-first-' +tabid).addClass('active');
    $('.js-tab-title-first-' +tabid).attr("aria-selected", "true");
  } else {
    $('.js-tab-title-first-' +tabid).removeClass('active');
    $('.js-tab-title-first-' +tabid).attr("aria-selected", "false");
  }
  if ($(this).hasClass('current')) {
    //Close the current section
    // $('.js-tab-title-' +tabid).removeClass('current');
    // $('.panel-' +tabid).hide();
  } else {
    //close the prev section & open the newly click
    $('.js-tab-title-' +tabid).removeClass('current');
    $('.panel-' +tabid).hide(); //Side up all sections that are open & remove their open class
    $(this).addClass('current');
    $(this).attr("aria-selected", "true");
    var sectionToOpen = $(this).next('.collapsing-section');
    $(sectionToOpen).show();
  }
});