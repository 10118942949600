import $ from "jquery";

/* Open the first section on load */
$(".accordition__question").on('focus', function () {
  if (!$(this).data("mouseDown"))
    $(this).click();
});

$(".accordition__question").on('mousedown', function () {
  $(this).data("mouseDown", true);
});

$(".accordition__question").on('mouseup', function () {
  $(this).removeData("mouseDown");
});

$(".accordition__question").on('click', function (e) {
  if ($(this).hasClass('is-active')) {
    //Close the current section
    $('.accordition__question').removeClass('is-active');
    $('.accordition__answer').slideUp();
  } else {
    //close the prev section & open the newly click
    $('.accordition__question').removeClass('is-active');
    $('.accordition__answer').slideUp(); //Side up all sections that are open & remove their open class
    $(this).addClass('is-active');
    var sectionToOpen = $(this).next('.accordition__answer');
    $(sectionToOpen).slideDown();
  }

});