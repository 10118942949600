import $ from "jquery";
import Flickity from "flickity";

export default {
  init() {
    // JavaScript to be fired on all pages
    // console.log('common');
  },
  finalize() {
    // console.log('common finalize');
    
    /**
     * Flickity slider
     */
    if ($('.main-carousel').length > 0) {
      $(document).ready(function() {
        new Flickity(".main-carousel", {
          contain: true,
          // initialIndex: 0,
          pageDots: false,
          wrapAround: false,
          cellAlign: 'left',
          accessibility: false,
          prevNextButtons: true
        });
        $('.latest-news__box').attr("aria-hidden","false");
      });
    }


    /**
     * lazysizes js. Lazyload bg images. Convert data-bg to its css bg when in view. 
    */
     document.addEventListener("lazybeforeunveil", function(e) {
      var bg = e.target.getAttribute("data-bg");
      if (bg) {
        e.target.style.backgroundImage = "url(" + bg + ")";
      }
    });


    /**
     * Menu functions
     */
    $(window).ready(function() {
      /* Open menu */
      $(".menu-toggle").on("click", function() {
        $("body") .toggleClass("menu-open");
        $('.menu-wrapper li')
          .each(function (i) {
          var item = $(this);
          setTimeout(function () {
            item.toggleClass('loaded', !item.hasClass('loaded'));
          }, 90 * i);
        });
      });
    
      /* Close menu on big screen */
      if ($(window).width() > 991) {
        $("body").removeClass("menu-open");
      }
    });
    
    /**
     * Menu functions - open sub-menu on focus click
     */
     $(".menu-item").on("click", function(e) {
      let current = $(this).siblings('.sub-menu');
      let arrows = $('.header__menu-dropdown');
      let arrow = $(this).next('.header__menu-dropdown');

      if (current.hasClass('active')){
        current.removeClass('active');
        arrow.removeClass('active');
      } else {
        $('.sub-menu').removeClass('active');
        current.toggleClass('active');
        arrows.removeClass('active');
        arrow.toggleClass('active');
      }
      e.stopPropagation();
    });

    $(".header__menu-dropdown").on("click", function(e) {
      let current = $(this).siblings('.sub-menu');
      let arrows = $('.header__menu-dropdown');
      let arrow = $(this);
      
      if (arrow.hasClass('active')){
        arrow.removeClass('active');
      } else {
        arrows.removeClass('active');
        arrow.toggleClass('active');
      }

      
      if (current.hasClass('active')){
        current.removeClass('active');
      } else {
        $('.sub-menu').removeClass('active');
        current.toggleClass('active');
      }
      e.stopPropagation();
    });
  

    // $(".menu-item-has-children").on("click", function(e) {
    //   e.preventDefault();
    //   $(this)
    //     .siblings('.sub-menu')
    //     .addClass('active');
    //   $(this)
    //     .siblings('.menu-main-arrow')
    //     .addClass('active');
    // });


    $(".menu-toggle").on("click", function(e) {
      e.preventDefault();
      var changetext = $('.hamburger__text');    
      changetext.text(changetext.text() == 'Stäng' ? 'Meny' : 'Stäng');
    });



    /**
     * Add class to header on scroll
     */
    function checkScroll(){
      if ($(window).scrollTop() >= 150) {
        $('header')
          .addClass('header--scrolled');
      } else {
        $('header')
          .removeClass('header--scrolled');
      }
    }

    $(document).ready(function() {
      checkScroll();
      $(window).scroll(checkScroll);
    });



    /**
     * Search toggle classes in header
     */
    $('.header__search').click(function(){
      $('.header__search-form-wrapper').addClass('active');
      $('.header__search-input').focus();
      $('.site-overlay')
        .fadeIn()
        .addClass('active');
    });

    $('.header__search-form-close').click(function(){
      $('.header__search-form-wrapper').removeClass('active');
      $('.header__search').focus();
      $('.site-overlay')
        .fadeOut()
        .removeClass('active');
    });



    /**
     * Video block - start video on click img
     */
    $('.video-image').click(function(ev){
      $(this).addClass('hidden');
      $(this).parent().addClass('playing');
      $(this)
        .prev()
        .children('iframe')[0].src += "&autoplay=1";
        $(this)
        .prev()
        .children('iframe')
        .addClass('playing');
      ev.preventDefault();
    });


    
    /**
     * Sidebar menu functions
     */
    $('.sidebar-menu__trigger').click(function(){
      $(this).toggleClass('sidebar-menu__trigger--active');
      $(this)
        .next('.sidebar-menu__sub_menu')
        .toggleClass('sidebar-menu__first-level--active');
    });

    /* Sub menu second level */
    $('.sidebar-menu__second-level--active')
    .closest('.sidebar-menu__first-level')
    .addClass('sidebar-menu__first-level--active');

    /* Sub menu second level trigger */
    $('.sidebar-menu__second-level--active')
    .closest('.sidebar-menu__first-level')
    .children('.sidebar-menu__trigger')
    .addClass('sidebar-menu__trigger--active');

    $('.sidebar-menu__mobile-trigger').click(function(){
      $('.sidebar-text').toggleClass('sidebar-text--active');
      $(this).toggleClass('sidebar-menu__mobile-trigger--active');
      $(this)
        .next().find('.sidebar-menu')
        .toggleClass('visible');
    });



    /**
     * Staff popup function
     */
    $('.js-coworker-img').click(function (e) {
      var $this = $(this);
      $('.coworker-item__img--popup-wrap').removeClass('active');
      $($this).next('.coworker-item__img--popup-wrap').addClass('active');
    });

    $('.js-coworker-item__img--close-popup').click(function (e) {
      $('.coworker-item__img--popup-wrap').removeClass('active');
    });

    

    /**
     * Add class to target blank link inside page content
     */
    $('.main-content a[target="_blank"], .accordition__answer a[target="_blank"]').addClass('external-link');

    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
