import $ from "jquery";
import debounce from "debounce";
import "imports-loader?jQuery=jquery,define=>false,module.exports=>false!jquery-validation";

class DonationForm {
  constructor() {
    if ($(".form-step").length) {
      const form = $(".form-step");
      this.loadForm(form.find("ol"));
      this.stepToggle(form.find("ol"));
      this.setData(form);
      this.toggleFoldout();
      this.toggleFoldoutPan();
      this.submitPan(form);
      this.submitOrder(form);
      this.validateStep(form);
    }
  }

  /**
   * Toggle Form steps
   *
   * @param {func}
   *
   * @return {void}
   */
  stepToggle(form) {
    /** @type {object} instance of DonationForm */
    const me = this;

    $(document).on("click", ".js-hash-change", function (e) {
      /** @type {string} Get href from <a> */
      const href = $(this).attr("href");

      if (href) {
        /** @type {string} Puts hash in variable, and removes the # character */
        const hash = href.substring(1);

        if (hash === "step-4") {
          me.validateForm(form);
        }

        /** @type {func} Find current step/<li> */
        const current_li = form.find("li." + hash);

        /** @type {func} Find all siblings */
        const siblings = $(current_li).siblings();

        // Close all childrens
        siblings.each(function () {
          // Slide up this sibling
          $(this).find(".form-step-container").slideUp();

          // Remove active class from this sibling
          $(this).removeClass("active").attr('aria-hidden', 'true');;
        });

        // Slide down container for current step
        $(current_li).find(".form-step-container").slideDown();

        // Set active class
        $(current_li).addClass("active").attr('aria-hidden', 'false');

        if (hash === "step-1") {
          $(current_li).find(".form-step-container input").focus();
        }
        if (hash === "step-2") {
          $(current_li).find(".form-step-container .checked-radio").focus();
        }
        if (hash === "step-3") {
          $(current_li).find(".js-toggle-foldout-pan").focus();
        }
        if (hash === "step-4") {
          $(current_li).find(".js-submit-order").focus();
        }
        // Scroll form into position TODO: Check if we need to scroll to right position in form
        // me.scrollForm(form, href);

        // Handle form step position
        me.handleFormStep(form);
      }
    });
  }

  /**
   * Scroll form into postion after revealing new step TODO: This is a bit buggy
   *
   * @param {func}
   * @param {func}
   *
   * @return {void}
   */
  scrollForm(form, href) {
    /** @type {string} Puts hash in variable, and removes the # character */
    const hash = href.substring(1);

    /** @type {func} Find current step/<li> */
    const current_li = form.find("li." + hash);

    $("html, body")
      .delay("200")
      .animate(
        {
          scrollTop: $(current_li).offset().top - 90,
        },
        500
      );
  }

  /**
   * Load multi step form
   *
   * @param {func}
   *
   * @return {void}
   */
  loadForm(form) {
    /** @type {object} instance of DonationForm */
    const me = this;

    // Wait for document to be ready
    $(document).ready(function () {
      // Load form data from current session
      me.loadSessionData();

      // Check if hash exists
      if (window.location.hash) {
        /** @type {string} Puts hash in variable, and removes the # character */
        const hash = window.location.hash.substring(1);

        // Validate form data before going to final step
        me.validateForm(form);

        /** @type {func} Set current step/<li> */
        const current_li = form.find("li." + hash);

        // Slide down container
        $(current_li).find(".form-step-container").slideDown();

        // Set active class
        $(current_li).addClass("active");

        // Handle form step position
        me.handleFormStep(form);
      } else {
        /** @type {func} If no step is chosen (URL hash), activate first step */
        let firstitem = form.find("li:first-child");

        // Slide down container
        $(firstitem).find(".form-step-container").slideDown();

        // Set active class
        $(firstitem).addClass("active");
      }
    });
  }

  /**
   * Handle the step form is in
   *
   * @param {func}
   *
   * @return {void}
   */
  handleFormStep(form) {
    /** @type {func} Find current step/<li> */
    const current_li = form.find("li.active");

    // Add class "done" to all previous steps
    $(current_li).prevAll("li").addClass("done");

    // Remove class "done" to all next steps
    $(current_li).nextAll("li").removeClass("done");
  }

  /**
   * Load data from session and populate form input
   *
   * @return {void}
   */
  loadSessionData() {
    if (sessionStorage.getItem("DonationForm")) {
      /**
       * Load data from sessionStorage
       *
       * @type {object}
       */
      const data = JSON.parse(sessionStorage.getItem("DonationForm"));

      /** @type {string} */
      let amount = data.amount;

      if (data.float_amount) {
        /** @type {string} */
        amount = data.float_amount;

        this.amountIsFloating(true);
      } else {
        this.amountIsFloating(false);
      }

      // Remove class from step 3 button if value exists
      if (
        data.payment_interval &&
        data.name &&
        data.lastname &&
        data.email &&
        data.address &&
        data.postalcode &&
        data.city
      ) {
        $(".js-donate-btn3").removeClass("disabled");
        $(".js-donate-btn3").attr("disabled", false);
      }

      // Set values of inputs
      $("#" + data.amount).prop("checked", true);
      $("input[name=float_amount]").val(data.float_amount);
      $("#" + data.payment_interval).prop("checked", true);
      $("input[name=name]").val(data.name);
      $("input[name=lastname]").val(data.lastname);
      $("input[name=tel]").val(data.tel);
      $("input[name=email]").val(data.email);
      $("input[name=address]").val(data.address);
      $("input[name=postalcode]").val(data.postalcode);
      $("input[name=city]").val(data.city);
      $("input[name=policy]").val(data.policy);


      // Change words for payment interval on front end
      let costmethod;

      // Hide Swish button if recurring payment
      if (data.payment_interval != "onetime") {
        $(".js-submit-order[data-paymentmethod=swish]").hide();
        costmethod = "Månadsgåva";
      } else {
        $(".js-submit-order[data-paymentmethod=swish]").show();
        costmethod = "Engångsgåva";
      }

      /** @type {string} HTML content */
      const html = `
      <div class="form-step-order-amount">Du betalar<span>${amount} kr</span></div>
      <p class="tac">Typ av betalning: <strong>${costmethod}</strong></p>
      <div class="form-step-order-data">
      <p>Förnamn: <strong>${data.name}</strong></p>
      <p>Efternamn: <strong>${data.lastname}</strong></p>
      <p>Telefon: <strong>${data.tel}</strong></p>
      <p>Email: <strong>${data.email}</strong></p>
      <p>Adress: <strong>${data.address}, ${data.postalcode} ${data.city}</strong></p>
      </div>
      `;

      // Set value on order info
      $(".js-order-content").html(html);
    }
  }

  /**
   * Set data to session when user interact with form
   *
   * @param {func}
   *
   * @return {void}
   */
  setData(form) {
    /** @type {object} instance of DonationForm */
    const me = this;

    // On keyup save to sessionStorage, use debounce in order to not spam trigger
    $(form)
      .find("input")
      .keyup(
        debounce(function () {
          me.setSessionStorage(form);
        }, 300)
      );

    // On change save to sessionStorage, use debounce in order to not spam trigger
    $(form)
      .find("input")
      .change(
        debounce(function () {
          me.setSessionStorage(form);
        }, 300)
      );
  }

  /**
   * Handle data in form
   *
   * @param {func}
   *
   * @return {void}
   */
  setSessionStorage(form) {
    /**
     * Set all form values in one object
     *
     * @type {object}
     */
    const data = {
      payment_interval: form.find("input[name=payment_interval]:checked").val(),
      amount: form.find("input[name=amount]:checked").val(),
      float_amount: form.find("input[name=float_amount]").val(),
      name: form.find("input[name=name]").val(),
      lastname: form.find("input[name=lastname]").val(),
      tel: form.find("input[name=tel]").val(),
      email: form.find("input[name=email]").val(),
      address: form.find("input[name=address]").val(),
      postalcode: form.find("input[name=postalcode]").val(),
      city: form.find("input[name=city]").val(),
      policy: form.find("input[name=policy]:checked").val(),
    };

    /** @type {string} */
    let amount = data.amount;

    if (data.float_amount) {
      /** @type {string} */
      amount = data.float_amount;

      this.amountIsFloating(true);
    } else {
      this.amountIsFloating(false);
    }

    // Change words for payment interval on front end
    let costmethod;

    // Hide Swish button if recurring payment
    if (data.payment_interval != "onetime") {
      $(".js-submit-order[data-paymentmethod=swish]").hide();
      costmethod = "Månadsgåva";
    } else {
      $(".js-submit-order[data-paymentmethod=swish]").show();
      costmethod = "Engångsgåva";
    }

    // Save data to sessionStorage
    sessionStorage.setItem("DonationForm", JSON.stringify(data));

    /** @type {string} HTML content */
    const html = `
      <div class="form-step-order-amount">Du betalar<span>${amount} kr</span></div>
      <p class="tac">Typ av betalning: <strong>${costmethod}</strong></p>
      <div class="form-step-order-data">
      <p>Förnamn: <strong>${data.name}</strong></p>
      <p>Efternamn: <strong>${data.lastname}</strong></p>
      <p>Telefon: <strong>${data.tel}</strong></p>
      <p>Email: <strong>${data.email}</strong></p>
      <p>Adress: <strong>${data.address}, ${data.postalcode} ${data.city}</strong></p>
      </div>
     `;

    // Set value on order info
    $(".js-order-content").html(html);
  }

  /**
   * Toggle foldout inside form
   *
   * @return {void}
   */
  toggleFoldout() {
    $(document).on("click", ".js-toggle-foldout", function (e) {
      /** @type {func} Clicked toggle */
      const toggle = $(this);

      // Slide down container
      $(toggle).toggleClass("active");

      /** @type {func} Toggle foldout */
      const foldout = $(this).next(".form-step-foldout");

      // Slide down container
      $(foldout).slideToggle();
    });
  }


  /**
   * Toggle PAN foldout inside form and focus input
   *
   * @return {void}
   */
  toggleFoldoutPan() {
    $(document).on("click", ".js-toggle-foldout-pan", function (e) {
      $(".form-step-foldout-pan").slideToggle("slow", function() {
        if ($(this).is(':visible')) {
          $("#input-field-pan").focus();
        }
      });
    });

    /** Disable form form entering when pressing Enter-key if not submit */
    $(document).on("keydown", ":input:not(textarea):not(:submit)", function(event) {
      return event.key != "Enter";
    });

    $('#pan-button').on("click",function(e){
      e.preventDefault();
    });
  }
  
  
  /**
   * Submit PAN form within form to collect user data and prefill
   * fields in main form
   *
   * @param {func}
   *
   * @return {void}
   */
  submitPan(form) {
    /** @type {object} instance of DonationForm */
    const that = this;

    $(".js-submit-pan").on("click", function (e) {
      e.preventDefault();

      /** @type {string} Personal number from input */
      const pan = $(form).find("input[name=pan]").val();

      /** @type {string} Nonce */
      const nonce = $(form).find("input[name=donation_form_nonce]").val();

      $.ajax({
        method: "post",
        url: adminAjaxURL,
        data: {
          action: "get_person_data_spar",
          pan: pan,
          security: nonce,
        },
        beforeSend: () => {
          // Disable submit button
          $(this).text("Hämtar info");
          $(this).attr("disabled", true);
        },
        success: (response) => {
          // Enable submit button
          $(this).text("Hämta mina uppgifter");
          $(this).attr("disabled", false);

          // Handle error
          if (!response || response == 0) {
            alert(
              "Hoppsan, vi kunde inte hämta din information, kontrollera ditt personnummer eller fylla i information manuellt."
            );
            return;
          }

          // Populate user address data in form
          if (response.Adresser.Folkbokforingsadress) {
            /** @type {object} Get href from <a> */
            let a = {};

            // If object is array, get first item, otherwise just get object
            if (Array.isArray(response.Adresser.Folkbokforingsadress)) {
              /** @type {object} Get href from <a> */
              a = response.Adresser.Folkbokforingsadress[0];
            } else {
              /** @type {object} Get href from <a> */
              a = response.Adresser.Folkbokforingsadress;
            }

            $(form).find("input[name=address]").val(a.Utdelningsadress2).removeClass('error');
            $(form).find("input[name=postalcode]").val(a.PostNr).removeClass('error');
            $(form).find("input[name=city]").val(a.Postort).removeClass('error');
          }

          // Populate user data in form
          if (response.Persondetaljer) {
            $(form)
              .find("input[name=name]")
              .val(response.Persondetaljer.Fornamn)
              .removeClass('error');
            $(form)
              .find("input[name=lastname]")
              .val(response.Persondetaljer.Efternamn)
              .removeClass('error');
          }

          // Set form data in session storage
          that.setSessionStorage(form);
        },
        error: (error) => {
          // Enable submit button
          $(this).text("Hämta mina uppgifter");
          $(this).attr("disabled", false);

          alert(
            "Hoppsan, vi kunde inte hämta din information, kontrollera ditt personnummer eller fylla i information manuellt."
          );
        },
      });
    });
  }

  /**
   * Loads PayEx payment menu on different actions
   *
   * @param {func}
   *
   * @return {void}
   */
  submitOrder(form) {
    $(".js-submit-order").on("click", function (e) {
      e.preventDefault();

      /** @type {string} Nonce */
      const nonce = $(form).find("input[name=donation_form_nonce]").val();

      /** @type {string} Nonce */
      const payment_method = $(this).attr("data-paymentmethod");

      /** @type {string} Nonce */
      const button_text = $(this).text();

      /**
       * Load data from sessionStorage
       *
       * @type {object}
       */
      const order = JSON.parse(sessionStorage.getItem("DonationForm"));

      /** @type {object} */
      const data = {
        action: "payex_paymentmenu_init",
        order: {
          interval: order.payment_interval,
          total: order.float_amount ? order.float_amount : order.amount,
          name: order.name,
          lastname: order.lastname,
          phone: order.tel,
          email: order.email,
          address: order.address,
          postal: order.postalcode,
          city: order.city,
          payment_method: payment_method,
        },
        security: nonce,
      };

      $.ajax({
        method: "post",
        url: adminAjaxURL,
        data: data,
        beforeSend: () => {
          // Disable submit button
          $(this).text("Hanterar order");
          $(this).attr("disabled", true);
          //TODO: ADD loading overlay so user dont stop the AJAX call
        },
        success: (response) => {
          if (response.status === 200 && response.href) {
            // Redirect to hosted payment page
            window.location.replace(response.href);
          }

          // TODO: Show error message in a nice way
          if (response.status != 200) {
            if (response.message) {
              alert(response.message);
            } else {
              alert(
                "Hoppsan, något gick fel. Försök igen eller kontakta Ericastiftelsen givarservice@ericastiftelsen.se"
              );
            }
          }

          // Enable submit button
          $(this).text(button_text);
          $(this).attr("disabled", false);
        },
        error: (error) => {
          console.log(error);

          // TODO: Show error message
          alert("Hoppsan, nåt gick fel");

          // Enable submit button
          $(this).text(button_text);
          $(this).attr("disabled", false);
        },
      });
    });
  }

  /**
   * Loads PayEx payment menu on different actions
   * TODO: Add UI validation messages
   *
   * @param {func}
   *
   * @return {void}
   */
  validateForm(form) {
    const wrapper = $(form).closest("form");

    if (sessionStorage.getItem("DonationForm")) {
      /**
       * Load data from sessionStorage
       *
       * @type {object}
       */
      const data = JSON.parse(sessionStorage.getItem("DonationForm"));

      // Set values of inputs
      if (
        data.payment_interval &&
        data.name &&
        data.lastname &&
        data.email &&
        data.address &&
        data.postalcode &&
        data.city
      ) {
        $(wrapper).addClass("success");
        $(".js-submit-order").attr("disabled", false);
        $(".js-donate-btn3").removeClass("disabled").attr("aria-disabled", false).attr("disabled", false).attr("href", "#step-4");
      } else {
        $(wrapper).addClass("error");
        $(".js-submit-order").attr("disabled", true);
        $(".js-donate-btn3").removeClass("success").attr("aria-disabled", true).attr("disabled", true).attr("href", "javascript:void(0)");;
      }
    } else {
      $(wrapper).addClass("error");
      $(".js-submit-order").attr("disabled", true);
    }
  }

  /**
   * Validate fields inside form step
   *
   * @return {void}
   */
  validateStep() {
    $(".form-step").validate({
      rules: {
        amount: {
          required: false,
        },
        float_amount: {
          required: false,
        },
        firstname: {
          required: true,
        },
        lastname: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        tel: {
          required: false,
        },
        address: {
          required: true,
        },
        postalcode: {
          required: true,
        },
        city: {
          required: true,
        },
        policy: {
          required: true,
          minlength: 1
        }
      },
      messages: {
        policy: {
          required: "",
        }
      }
    });

    $(".js-donate-step3 input").on("keyup", function () {
      if ($(".form-step").valid()) {
        $(".js-donate-btn3").addClass("success").attr("aria-disabled", false).attr("disabled", false).attr("href", "#step-4");
      } else {
        $(".js-donate-btn3").removeClass("success").addClass("disabled").attr("aria-disabled", true).attr("disabled", true).attr("href", "javascript:void(0)");
      }
    });
    $(".js-donate-step3 input").on("change", function () {
      if ($(".form-step").valid()) {
        $(".js-donate-btn3").addClass("success").attr("aria-disabled", false).attr("disabled", false).attr("href", "#step-4");
      } else {
        $(".js-donate-btn3").removeClass("success").addClass("disabled").attr("aria-disabled", true).attr("disabled", true).attr("href", "javascript:void(0)");
      }
    });
  }

  /**
   * Toggle Form steps
   *
   * @param {bool}
   *
   * @return {void}
   */
  amountIsFloating(isFloating) {
    if (isFloating) {
      $(".form-step").find("input[name=amount]").parent().addClass("disable");
      $(".form-step").find(".amount-wrapper").removeClass("disable");
    } else {
      $(".form-step")
        .find("input[name=amount]")
        .parent()
        .removeClass("disable");
      $(".form-step").find(".amount-wrapper").addClass("disable");
    }
  }
}

new DonationForm();
