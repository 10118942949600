import $ from "jquery";

class HandleApplication {
  constructor() {
    this.handleApp();
  }

  /**
   * Accept or deny applications on my account
   */
  handleApp() {

    $(".handle-application").on("click", function(e) {
      e.preventDefault();

      // Variables
      var appID = $(this).attr('data-app-id');
      var appAction = $(this).attr('data-app-action');
      var data = {
        action: "change_app_status",
        app_id: appID,
        app_action: appAction,
      };

      // ajax handling
      $.ajax({
        method: "post",
        url: adminAjaxURL,
        data: data,
        beforeSend: function() {
          $("body").addClass('loading');
        },
        success: function(data) {
          if (data && data.updated && data.app_action == 'yes') {
            $('.app-status').show();
            $('.app-status span').text('Tackat ja');
            location.reload();
          }
          if (data && data.updated && data.app_action == 'no') {
            $('.app-status').show();
            $('.app-status span').text('Tackat nej');
            location.reload();
          }
          $('.app-button-wrapper').hide();
        },
        error: function(error) {
          console.log(error);
        }
      });
    });
  }

}

new HandleApplication();
