import $ from "jquery";
import "imports-loader?jQuery=jquery,define=>false,module.exports=>false!jquery-validation";

class BankID {
  constructor() {
    this.validateForm();
    this.qrInterval = null;
    this.generateQR();
    this.initCollect();
  }

  // If we are on auth page, start checking for an order status
  initCollect() {
    const me = this;

    if ($("body.auth").length) {
      /** @type {object} Get all URL params */
      var urlParams = new URLSearchParams(window.location.search);

      /** @type {string} */
      var action = urlParams.get("action");
      var orderRef = urlParams.get("orderRef");
      var security = urlParams.get("security");

      // Init the status collect of current transaction
      me.collect(orderRef, action, security);
    }
  }

  // If the BankID QR img is there, it's time to render a QR code
  generateQR() {
    const me = this;

    if ($("body.auth").length) {
      /** @type {object} Get all URL params */
      var urlParams = new URLSearchParams(window.location.search);

      /** @type {string} */
      var qrStartToken = urlParams.get("qr_start_token");
      var qrStartSecret = urlParams.get("sec");
      var startTime = urlParams.get("start_time");
      var action = urlParams.get("action");

      // Generate a new QR code every second
      if (
        $(".bankid-qr").length &&
        qrStartToken &&
        qrStartSecret &&
        startTime &&
        action
      ) {
        me.generateNewQR(qrStartToken, qrStartSecret, startTime, action);
      }
    }
  }

  // Generates a new QR code every other second
  generateNewQR(qrStartToken, qrStartSecret, startTime, action) {
    const me = this;

    this.qrInterval = setInterval(function () {
      $.ajax({
        url: adminAjaxURL,
        method: "POST",
        dataType: "json",
        data: {
          action: "generate_next_qr_code",
          qr_start_token: qrStartToken,
          sec: qrStartSecret,
          start_time: startTime,
          bankid_action: action,
        },
        success: (data) => {
          if (data.img_url) {
            $(".bankid-qr").attr("src", data.img_url);
          } else {
            clearInterval(this.qrInterval);

            $(".bankid-status").text(
              "Något gick fel, din identifiering har avbrutits."
            );

            me.showRedirectMessage(true, data.redirect);
          }
        },
        error: (error) => {
          clearInterval(this.qrInterval);

          me.showRedirectMessage(true, data.siteURL);
        },
      });
    }, 1000);
  }

  // Init the status collect of current transaction
  collect(order_ref, action, security) {
    const me = this;

    $.ajax({
      url: adminAjaxURL,
      method: "POST",
      dataType: "json",
      data: {
        action: "collect_order_status",
        order_ref: order_ref,
        bankid_action: action,
        bankid_nonce: security,
      },
      success: (data) => {
        $(".bankid-status").text(data.message);

        if (data.status == "pending") {
          setTimeout(function () {
            me.collect(data.order_ref, data.action, data.security);
          }, 1000);
        }

        if (data.status == "complete") {
          clearInterval(this.qrInterval);

          me.showRedirectMessage(false, data.redirect);
        }

        if (data.status == "error" || data.status == "failed") {
          clearInterval(this.qrInterval);

          me.showRedirectMessage(true, data.redirect);
        }
      },
      error: (error) => {
        clearInterval(this.qrInterval);

        me.showRedirectMessage(true, siteURL);
      },
    });
  }

  /**
   * Shows correct redirect message before redirecting
   *
   * @type {string}
   */
  showRedirectMessage(isError, redirect) {
    let seconds = 4;

    const string = isError
      ? "Du kommer att skickas tillbaka om"
      : "Du kommer att skickas till nästa steg om";

    let x = setInterval(function () {
      seconds--;

      $(".bankid-redirect").text(`${string} ${seconds}`);

      if (seconds === 0) {
        clearInterval(x);
        window.location.href = redirect;
      }
    }, 1000);
  }

  // bankid form validation
  validateForm() {
    $(".js-validate-bankid").validate({
      rules: {
        bankid_policy: {
          required: true,
        },
      },
      messages: {
        bankid_policy: {
          required: "",
        },
      },
    });

    $(".js-validate-bankid input").on("change", function () {
      if ($(".js-validate-bankid").valid()) {
        $(".js-validate-bankid button").attr("disabled", false);
      } else {
        $(".js-validate-bankid button").attr("disabled", true);
      }
    });
  }
}

new BankID();
