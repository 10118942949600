export default {
  init() {
    // console.log("home");



  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
