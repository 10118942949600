import $ from "jquery";
import "./style.scss";

import Router from "./javascripts/util/Router";
import common from "./javascripts/routes/common";
import home from "./javascripts/routes/home";
import about from "./javascripts/routes/about";
import "./javascripts/DonationForm";
import "./javascripts/MyAccount";
import "./javascripts/Forms";
import "./javascripts/Tabs";
import "./javascripts/Accordition";
import "./javascripts/Tabs";
import "./javascripts/vendors/lazysizes.min";
import "./javascripts/vendors/what-input";
import "lazysizes/plugins/unveilhooks/ls.unveilhooks";
import "./javascripts/flexible-content/map";
import "./javascripts/ajax/load_more_news";
import "./javascripts/ajax/BankID";
import "./javascripts/ajax/User";
import "./javascripts/ajax/HandleApplication";
import "./javascripts/donation/DonationForms";

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  /** All pages */
  common,
  /** Home page */
  home,
  /** About Us page, note the change from about-us to aboutUs. */
  about,
});

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
